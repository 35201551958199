.icon svg {
  color: #92c7ca;
  height: 25px;
  width: 25px;
  margin: 0;
}

.icon_disabled svg {
  color: rgba(146, 199, 202, 0.3);
}

.boxItem {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  border-bottom-width: 4px;
  border-bottom-color: transparent;
  cursor: pointer;
}

.boxItem {
  text-decoration: none;
}

.checked {
  border-bottom-color: #92c7ca;
}
