html {
  height: 100%;
  overflow: hidden;
  position: relative;
}
body {
  height: 100%;
  overflow: auto;
  position: relative;
}

.leaflet-container {
  height: 100%;
}

.truck-marker {
  transform-origin: center;
  transition: transform 0.1s ease-out;
}

.marker-custom {
  width: 0;
  height: 0;
}

.marker-custom span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  color: #fff;
  background: rgb(68, 115, 243);
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker-custom b {
  transform: rotateZ(135deg);
  text-align: center;
  line-height: 1;
  font-size: 12px;
}

.leaflet-routing-container-hide {
  display: none !important;
}

.circle-badge-custom span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  color: white;
}