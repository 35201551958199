.icon svg {
  color: #2a3232;
  height: 40px;
  width: 40px;
  margin: 0;
}

.checkCircleIcon {
    color: #10790e;
    width: 20px;
    height: 20px;
  }
  