.tabNav {
  display: flex;
  justify-content: center;
  height: 64px;
  bottom: 0;
  position: relative;
  z-index: 999;
  background-color: white;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.15);
}
