.icon svg {
  color: rgba(146, 199, 202, 0.3);
  height: 60px;
  width: 60px;
  margin: 0;
}

.icon_activated svg {
  color: #92c7ca;
  height: 60px;
  width: 60px;
  margin: 0;
}

.checkCircleIcon {
  color: #10790e;
  width: 20px;
  height: 20px;
}
